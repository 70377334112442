/* eslint-disable react/no-danger */
import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/layouts/layout';
import SEO from '../../components/seo';
import Articles from '../../components/blog/articles';

export const query = graphql`
  query GetCategoryPageSettingsWithLocale($locale: String!, $category_id: String!) {
    pageSettings: contentfulBlogPageSettings(node_locale: { eq: $locale }) {
      title
      description {
        description
      }
      keywords
      mainHeading
      categoriesText
      shareCalloutText
      paginationPrevious
      paginationNext
    }
    category: contentfulBlogArticleCategory(id: { eq: $category_id }) {
      title
      slug
    }
    articles: allContentfulBlogArticle(
      filter: {
        node_locale: { eq: $locale }
        categories: { elemMatch: { id: { eq: $category_id } } }
      }
      limit: 1000
    ) {
      nodes {
        title
        slug
        excerpt {
          excerpt
        }
        categories {
          title
          slug
        }
        author {
          firstName
          lastName
          slug
          avatar {
            resize(width: 100, height: 100) {
              src
            }
          }
        }
      }
    }
  }
`;

function CategoryPage({ data }) {
  const { pageSettings, category, articles } = data;

  return (
    <Layout>
      <SEO
        title={pageSettings.title}
        description={pageSettings.description.description}
        keywords={pageSettings.keywords}
      />
      <section
        className="flex flex-col max-w-4xl m-auto"
        itemScope
        itemType="https://schema.org/Blog"
      >
        <header className="my-8">
          <h1 className="font-extrabold md:text-5xl text-left">
            Category:{' '}
            <Link className="text-theme-purple lowercase" to={`/blog/category/${category.slug}`}>
              {category.title}
            </Link>
          </h1>
          <p className="text-lg md:text-2xl text-gray-700">
            <b>Learn about remote work</b>, read first about new <b>platform updates</b> and stay in
            the loop with remote work news.{' '}
            <strong>Strong views on remote work, management and company culture.</strong>
          </p>
        </header>
        <main className="leading-normal">
          <Articles articles={articles.nodes} pageSettings={pageSettings} />
        </main>
      </section>
    </Layout>
  );
}

export default CategoryPage;
